import {useEffect} from "react";
import React from 'react';

export default function NotFound() {
  // const navigate = useNavigate();
  //
  // useEffect(() => {
  //   navigate('/');
  // }, [])

  return (
    <div> 404 Page Not found Redirecting to home</div>
  );
}
